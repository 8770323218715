@import './animations';
@import './fixes';

body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}
