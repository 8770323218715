/* stylelint-disable selector-class-pattern, color-format/format */

// Workaround for https://github.com/mui-org/material-ui/issues/10000
body {
  padding: 0 !important;
}

// Fixes button icon having margin even without text
.mdc-button .mdc-button__icon:only-child {
  margin-right: 0;
}

// Workaround for toolbar text and link text not using --mdc-theme-on-primary
.mdc-toolbar {
  color: var(--mdc-theme-on-primary, #ffffff);

  .mdc-toolbar__icon {
    color: inherit;
  }
}
